import React from "react";
import { Navigate } from "react-router-dom";

// publicRoutes...................
import Login from "../pages/Auth/Login";
import PageNotFound from "../pages/404/PageNotFound";

// authProtectedSpoc..............
import Dashboard from "../pages/Dashboard";
import Internship from "../pages/Internship";
// import Company from "../pages/Company";
import Placement from "../pages/Placement";
import Resume from "../pages/Resume";
import Report from "../pages/Report";
import Support from "../pages/Support";
import AcademyProgram from "../pages/AcademyProgram";
import InstitutionStatus from "../pages/InstitutionStatus";
import CorporateProgram from "../pages/CorporateProgram";
import Educator from "../pages/Educator";
import NewsEvents from "../pages/News&Events";
import Publication from "../pages/Publication";
import AwardsRecognition from "../pages/Awards&Recognition";

import Settings from "../pages/Auth/Settings";
import InternshipApproval from "../pages/Internship/InternApproval";
// import Membership from "../pages/Membership";
import TalentConnect from "../pages/TalentConnect";
import Agreements from "../pages/Membership/agreements";
// import Payment from "../pages/Membership/payment";
import InstitutionalProfile from "../pages/Membership/InstitutionalProfile/InstitutionalProfile";
import FeedbackForm from "../pages/Feedback";
// Admin
import AdminEducator from "../pages/Admin/Educator";
import Academy from "../pages/Admin/Academy";
import Institute from "../pages/Admin/Institute";
import AdminStaff from "../pages/Admin/Staff";
import ApplicationsPage from "../pages/TalentConnect/ApplicationsPage";
import JobDescriptionPage from "../pages/TalentConnect/JobDescriptionPage";
import JobPostPage from "../pages/TalentConnect/JobPostPage";
import CompanyPage from "../pages/TalentConnect/CompanyPage";
import Notification from "../pages/Notifications";
import Apsche from "../pages/Certificate/Apsche Certificate/Apsche";

const authProtected = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    roles: [
      "spoc",
      "manager",
      "management",
      "educator",
      "account_manager",
      "talent_module",
      "admin",
      "staff",
      "talent",
    ],
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

//

const authProtectedRoutes = [
  {
    path: "/feedback",
    component: <FeedbackForm />,
    roles: [
      "spoc",
      "manager",
      "management",
      "educator",
      "account_manager",
      "admin",
    ],
  },
  {
    path: "/settings",
    component: <Settings />,
    roles: [
      "spoc",
      "manager",
      "management",
      "educator",
      "account_manager",
      "admin",
      "staff",
    ],
  },

  {
    path: "/internship",
    component: <Internship />,
    roles: [
      "spoc",
      "manager",
      "management",
      "educator",
      "account_manager",
      "admin",
      "staff",
    ],
  },

  // {
  //   path: "/company",
  //   component: <Company />,
  //   roles: ["talent_module"],
  // },

  {
    path: "/placement",
    component: <Placement />,
    roles: ["talent_module"],
  },
  {
    path: "/internship-approval",
    component: <InternshipApproval />,
    roles: ["spoc"],
  },
  {
    path: "/apsche-certificate",
    component: <Apsche />,
    roles: ["spoc"],
  },
  {
    path: "/resume",
    component: <Resume />,
    roles: ["talent_module"],
  },

  {
    path: "/report",
    component: <Report />,
    roles: ["spoc", "manager", "talent_module", "admin"],
  },

  {
    path: "/support",
    component: <Support />,
    roles: ["spoc", "manager", "talent_module", "educator", "admin"],
  },

  // Admin..........................................

  {
    path: "/academy",
    component: <Academy />,
    roles: ["admin"],
  },
  {
    path: "/institute",
    component: <Institute />,
    roles: ["admin", "staff"],
  },
  {
    path: "/admin-educator",
    component: <AdminEducator />,
    roles: ["admin"],
  },
  {
    path: "/staff",
    component: <AdminStaff />,
    roles: ["admin", "staff"],
  },

  // Talent Connect.................................................
  {
    path: "/applications",
    component: <ApplicationsPage />,
    roles: ["talent"],
  },
  {
    path: "/job-description",
    component: <JobDescriptionPage />,
    roles: ["talent"],
  },
  {
    path: "/job-post",
    component: <JobPostPage />,
    roles: ["talent"],
  },
  {
    path: "/company",
    component: <CompanyPage />,
    roles: ["talent"],
  },

  //.................................................

  {
    path: "/academy-program",
    component: <AcademyProgram />,
    roles: ["account_manager", "manager", "educator", "admin"],
  },
  {
    path: "/institution-status",
    component: <InstitutionStatus />,
    roles: ["spoc", "manager", "account_manager", "admin"],
  },
  {
    path: "/corporate-program",
    component: <CorporateProgram />,
    roles: ["spoc", "management"],
  },
  {
    path: "/educator",
    component: <Educator />,
    roles: ["spoc", "educator", "management", "staff"],
  },
  {
    path: "/news-events",
    component: <NewsEvents />,
    roles: ["spoc"],
  },
  {
    path: "/publication",
    component: <Publication />,
    roles: ["spoc"],
  },
  {
    path: "/awards-recognition",
    component: <AwardsRecognition />,
    roles: ["spoc"],
  },

  // {
  //   path: "/membership",
  //   component: <Membership />,
  //   roles: ["manager", "management", "spoc"],
  // },
  {
    path: "/membership/agreements",
    component: <Agreements />,
    roles: ["manager", "management", "spoc", "admin"],
  },
  // {
  //   path: "/membership/payment",
  //   component: <Payment />,
  //   roles: ["manager", "management", "spoc","admin"],
  // },
  {
    path: "/membership/institutional-details",
    component: <InstitutionalProfile />,
    roles: ["manager", "management", "spoc", "admin"],
  },

  {
    path: "/talent-connect",
    component: <TalentConnect />,
    roles: ["spoc"],
  },
  {
    path: "/notifications",
    component: <Notification />,
    roles: ["spoc", "staff", "admin"],
  },
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
];

const publicRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    roles: [
      "spoc",
      "manager",
      "management",
      "educator",
      "account_manager",
      "talent_module",
      "admin",
      "staff",
      "talent",
    ],
  },
  // { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/", exact: true },
  // { path: "/login", component: <Login /> },
  // { path: "/page-not-found", component: <PageNotFound /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

export { authProtectedRoutes, publicRoutes, authProtected };
